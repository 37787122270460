import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.replace
VueRouter.prototype.push = function replace(location) { return originalPush.call(this, location).catch(err => err) }
const routes = [
  {
    path: '/',
    redirect: '/index',
    component: Layout,
    children: [{
      path: 'index',
      component: () => import('@/views/index/index'),
      meta: { title: '谈止科技，共享充电桩运营商',tabIndex:0 }
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: 'product',
      component: () => import('@/views/product/index'),
      meta: { title: '谈止科技，共享充电桩运营商',tabIndex:1 }
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: 'system',
      component: () => import('@/views/system/index'),
      meta: { title: '谈止科技，共享充电桩运营商',tabIndex:2 }
    }]
  },
  {
    path: '/',
    component: Layout,
    children: [{
      path: 'about',
      component: () => import('@/views/about/index'),
      meta: { title: '谈止科技，共享充电桩运营商',tabIndex:3 }
    }]
  },
  {
    path: '/illustrate',
    meta: { title: '谈止科技' },
    component: () => import('@/views/illustrate/index')
  },
  {
    path: '/illustrate/advert',
    meta: { title: '谈止科技' },
    component: () => import('@/views/illustrate/advert')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
