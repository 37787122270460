<template>
    <div>
        <div id="pc">
            <div class="flex-box">
                <div class="header-tab flex-alignc">
                    <img class="logo pointer" src="@/assets/logo.png" @click="$router.push({ path: '/' })">
                    <div class="flex-alignc tab-list">
                        <div class="tab-item pointer" :class="navIndex == index ? 'on' : ''" v-for="(item, index) in navList"
                            :key="index" @click="navClick(index, item)">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div id="move">
            <div style="position: relative;">
                <div class="flex-alignc-justifyb header">
                    <img class="logo" src="@/assets/move/logo.png" @click="$router.push({ path: '/' })"/>
                    <img class="more" src="@/assets/move/more.png" @click="showTab=!showTab"/>
                </div>
                <ul v-show="showTab" class="header-tab">
                    <li class="flex-alignc-justifyb" v-for="(item, index) in navList" :key="index" @click="navClick(index, item)">
                        <div>{{ item.name }}</div>
                        <img src="@/assets/move/right.png"/>
                    </li>
                </ul>
            </div>
        </div>
        <router-view />

        <div id="pc">
            <div class="footer">
                <div class="flex-alignc-justifyc footer-top" style="height: 75px;">
                    <div class="flex-alignc" style="margin-right: 50px;">
                        <img class="phone mr-10" src="@/assets/phone.png">
                        <span>24小时充电专线：400 005 9667</span>
                    </div>
                    <div class="flex-alignc">
                        <img class="location mr-10" src="@/assets/location.png">
                        <span>地址：合肥市高新区创新大道合肥 软件园二期E2号楼A座7楼</span>
                    </div>
                    <img class="top pointer" src="@/assets/top.png" @click="toTop">
                </div>
                <div class="flex-alignc-justifyc" style="height: 65px;">
                    <div class="flex-alignc">
                        <img class="national mr-10" src="@/assets/national.png">
                        <span>皖ICP备2023011395号 </span>
                    </div>
                    <div class="flex-alignc">
                        copyright © 合肥谈止科技有限公司
                    </div>
                </div>
            </div>
        </div>
        

        <div id="move" style="overflow: hidden;">
            <div class="footer">
                <div class="footer-item footer-top">
                    <div class="flex-alignc mb-10">
                        <img class="phone mr-10" src="@/assets/phone.png">
                        <span>24小时充电专线：400 005 9667</span>
                    </div>
                    <div class="flex-alignc">
                        <img class="location mr-10" src="@/assets/location.png">
                        <span>地址：合肥市高新区创新大道合肥 软件园二期E2号楼A座7楼</span>
                    </div>
                </div>
                <div class="footer-item flex">
                    <img class="national mr-10" src="@/assets/national.png">
                    <div>
                        <div>皖ICP备2023011395号 </div>
                        <div>copyright © 合肥谈止科技有限公司</div>
                    </div>
                </div>
            </div>
            <div class="footer-phone flex-column-center">
                <div class="flex-column-center footer-btn" @click="callPhone">
                    <div>24小时充电专线</div>
                    <div>400 005 9667</div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
export default {
    data() {
        return {
            navList: [
                {
                    name: '首页',
                    url: '/'
                },
                {
                    name: '谈止的产品',
                    url: '/product'
                },
                {
                    name: '平台系统',
                    url: '/system'
                },
                {
                    name: '关于我们',
                    url: '/about'
                }
            ],
            navIndex: 0,
            showTab:false
        }
    },
    watch:{
        '$route': { // $route可以用引号，也可以不用引号  监听的对象
            handler(newVal){
                this.navIndex = newVal.meta.tabIndex
            },
            deep: true, // 深度观察监听 设置为 true
            immediate: true, // 第一次初始化渲染就可以监听到
        }
    },
    methods: {
        navClick(index, item) {
            this.navIndex = index
            this.$router.push({ path: item.url })
            this.showTab=false
        },
        toTop() {
            document.documentElement.scrollTop = 0;
        },

        callPhone() {
            const a = document.createElement("a");
            a.href = "tel:400 005 9667"
            a.click();
        }
    }
}
</script>
  
<style lang="scss" scoped>
#pc {
    .flex-box {
        height: 64px;
    }
    .header-tab {
        position: fixed;
        width: 100%;
        height: 64px;
        background: #0F0C1F;
        padding: 0 400px;
        z-index: 100;
        .logo {
            width: 80px;
            height: 30px;
            margin-right: 150px;
        }

        .tab-list {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;

            .tab-item {
                margin-right: 80px;
                white-space: nowrap;
                &.on {
                    position: relative;
                    color: #34CB6E;

                    &::after {
                        content: ' ';
                        position: absolute;
                        left: 0;
                        bottom: -7px;
                        width: 100%;
                        height: 4px;
                        background: #34CB6E;
                    }
                }
            }
        }
    }
    .footer {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0F0C1F;
        background: #F0F0F0;
        .footer-top {
            position: relative;
            border-bottom: 1px solid #E2E2E2;
            .top {
                position: absolute;
                right: 80px;
                top: 50%;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
            }
        }
        .national {
            width: 18px;
            height: 20px;
        }
        .phone {
            width: 30px;
            height: 26px;
        }
        .location {
            width: 22px;
            height: 28px;
        }
        
    }
}

#move {
    .logo {
        width: 75px;
        height: 29px;
    }
    .more {
        width: 20px;
        height: 16px;
    }
    .header {
        padding: 15px;
    }
    .header-tab {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        background: #FFFFFF;
        z-index: 100;
        li {
            padding: 22px 12px;
            border-bottom: 1px solid #F0F0F0;
            &:last-child {
                border-bottom: none;
            }
            img {
                width: 8px;
                height: 13px;
            }
        }
    }
    .footer {
        margin-bottom: 65px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0F0C1F;
        background: #F0F0F0;
        .footer-item {
            padding: 10px 0 10px 20px;
        }
        .national {
            width: 18px;
            height: +20px;
        }
        .phone {
            width: 18px;
            height: 18px;
        }
        .location {
            width: 14px;
            height: 18px;
        }
        .footer-top {
            border-bottom: 1px solid #E2E2E2;
        }
    }
    .footer-phone {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        width: 100%;
        height: 65px;
        background: #FFFFFF;
        box-shadow: 0px -4px 7px 0px rgba(0,0,0,0.08);
        z-index: 200;
        .footer-btn {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            width: 80%;
            height: 44px;
            background: #34CB6E;
            border-radius: 44px;


            div:last-child {
                font-size: 16px;
            }
        }
    }
}
</style>
  