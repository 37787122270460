import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/permission'
import '@/styles/index.scss'
import '@/styles/media.scss'
import "lib-flexible"; // px2rem 自适应

// 引入 vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
//引入 vue-awesome-swiper 样式
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

import loadingImg from './assets/logo.png' //预先加载的图片
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {loading: loadingImg,})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
